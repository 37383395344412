import Splide, { PaginationData, PaginationItem } from '@splidejs/splide';
import { DirectionalSplide } from '../helpers/directionalSplide';

// Ignore the typescript issues on the pagination property and slideFocus, it's just incorrect typings from the library
export class MobileOnlyCardCarousel {
    carousel: Splide;
    constructor(element: HTMLElement) {
        this.carousel = new Splide(element, {
            accessibility: true,
            drag: true,
            perPage: 1,
            destroy: true,
            // @ts-ignore
            pagination: 'slider',
            // @ts-ignore
            slideFocus: false,
            speed: 500,
            keyboard: 'focused',
            breakpoints: {
                600: {
                    destroy: false,
                    padding: {
                        left: '2rem',
                        right: 'calc(2rem + 8.33%)'
                    },
                    gap: 'calc(2rem + 8.33%)'
                }
            },
            classes: {
                pagination: 'splide__pagination carousel-pagination',
                page: 'splide__pagination__page carousel-page'
            }
        });
        
        this.carousel.mount({ DirectionalSplide });
    }
}