import Splide, { PaginationData, PaginationItem } from '@splidejs/splide';

// We need to extend the library (via its own documentation) and add our listeners inside the mount method
// because they are not automatically reattached when the slider is destroyed / remounted at different breakpoints
export const DirectionalSplide = (Splide: Splide) => {
    const inactivePageClass = 'carousel-page-inactive';
    const targetSlideClass = 'is-target';
    const originSlideClass = 'is-origin';
    const classToTheRight = 'is-on-right';
    const classToTheLeft = 'is-on-left';

    const trackShift = (newIndex: number, oldIndex: number, destIndex: number) => {
        const direction = newIndex - oldIndex > 0 ? "right" : "left";
        const SplideEls = Splide.Components.Elements;
        if (SplideEls.slider) {
            SplideEls.slider.setAttribute('data-slide-direction', direction);
        }
        SplideEls.slides[oldIndex].classList.add(originSlideClass);
        SplideEls.slides[newIndex].classList.add(targetSlideClass);
        if (SplideEls.slides[newIndex - 1]) {
            SplideEls.slides[newIndex - 1].classList.add(classToTheLeft);
        }
        if (SplideEls.slides[newIndex + 1]) {
            SplideEls.slides[newIndex + 1].classList.add(classToTheRight);
        }
        if (SplideEls.slides[oldIndex - 1]) {
            SplideEls.slides[oldIndex - 1].classList.remove(classToTheLeft);
        }
        if (SplideEls.slides[oldIndex + 1]) {
            SplideEls.slides[oldIndex + 1].classList.remove(classToTheRight);
        }
    }

    const cleanupShift = (newIndex: number, oldIndex: number, destIndex: number) => {
        const SplideEls = Splide.Components.Elements;
        SplideEls.slides[oldIndex].classList.remove(originSlideClass);
        SplideEls.slides[newIndex].classList.remove(targetSlideClass);
    }

    const updatePagination = (data: PaginationData, prevItem: PaginationItem, activeItem: PaginationItem) => {
        data.items.forEach(item => {
            if (item.button != activeItem.button && (!prevItem || item.button != prevItem.button)) {
                item.button.classList.add(inactivePageClass);
            }
            else {
                item.button.classList.remove(inactivePageClass);
            }
        })
    }

    const setPaginationNumbers = () => {
        const start = '01';
        const end = Splide.length.toString().padStart(2, '0');
        const paginationEl = Splide.Components.Pagination.data.list;
        paginationEl.setAttribute('data-start-num', start);
        paginationEl.setAttribute('data-end-num', end);
    }

    return {
        mount() {
            Splide.on('move', trackShift.bind(this));
            Splide.on('moved', cleanupShift.bind(this));
            Splide.on('pagination:updated', updatePagination.bind(this));
            Splide.on('pagination:mounted', setPaginationNumbers.bind(this));
            if (Splide.Components.Elements.slides[1]) {
                Splide.Components.Elements.slides[1].classList.add(classToTheRight);
            }
        }
    }
}