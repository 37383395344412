import Splide, { PaginationData, PaginationItem } from '@splidejs/splide';
import { DirectionalSplide } from '../helpers/directionalSplide';

// Ignore the typescript issues on the pagination property and slideFocus, it's just incorrect typings from the library
export class VideoCarousel {
    carousel: Splide;
    constructor(element: HTMLElement) {
        this.carousel = new Splide(element, {
            type: "loop",
            accessibility: true,
            drag: true,
            perPage: 3,
            perMove: 1,
            // @ts-ignore
            pagination: 'slider',
            // @ts-ignore
            slideFocus: false,
            speed: 500,
            focus: 'center',
            keyboard: 'focused',
            padding: {
                left: '9rem',
                right: '9rem'
            },
            gap: '2rem',
            breakpoints: {
                1440: {
                    perPage: 3,
                    padding: {
                        left: '6rem',
                        right: '6rem'
                    }
                },
                1200: {
                    perPage: 3,
                    padding: {
                        left: '6rem',
                        right: '6rem'
                    }
                },
                1024: {
                    perPage: 2,
                    padding: {
                        left: '4rem',
                        right: '4rem'
                    }
                },
                600: {
                    perPage: 1,
                    padding: {
                        left: '3rem', 
                        right: '3rem'
                    }
                }
            },
            classes: {
                pagination: 'splide__pagination carousel-pagination carousel-pagination-nums',
                page: 'splide__pagination__page carousel-page'
            }
        });

        this.carousel.mount({ DirectionalSplide });
    }
}