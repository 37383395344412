import Splide from '@splidejs/splide';
import { TimelineSplide } from '../helpers/timelineSplide';

// Ignore the typescript issues on the pagination property and slideFocus, it's just incorrect typings from the library
export class CarouselInLinearCarousel {
    carousel: Splide;
    constructor(element: HTMLElement) {
        this.carousel = new Splide(element, {
            accessibility: true,
            drag: false,
            perPage: 1,
            // @ts-ignore
            pagination: 'slider',
            // @ts-ignore
            slideFocus: false,
            speed: 500,
            keyboard: 'focused',
            arrows: false,
            padding: {
                left: 0,
                right: 0
            },
            gap: '2rem',
            classes: {
                pagination: 'splide__pagination carousel-hex-pagination timeline-carousel-pagination',
                page: 'splide__pagination__page carousel-hex-page timeline-carousel-page'
            }
        });

        this.carousel.mount({ TimelineSplide });
    }
}