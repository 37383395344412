import { MobileOnlyCardCarousel } from '../carousels/classes/mobileOnlyCardCarousels';
import { CardCarousel } from '../carousels/classes/cardCarousel';
import { WideCarousel } from '../carousels/classes/wideCarousel';
import { SplitCarousel } from '../carousels/classes/splitCarousel';
import { LinearCarousel } from '../carousels/classes/linearCarousel';
import { VideoCarousel } from '../carousels/classes/videoCarousel';

const initAll = () => {
    initJobListingBlocks();
    initCardCarouselBlocks();
    initWideCarouselBlocks();
    initSplitCarouselBlocks();
    initLinearBlocks();
    initVideoCarouselBlocks();
}

const initJobListingBlocks = () => {
    const jobListingBlockCarousels = [].slice.call(document.querySelectorAll('[data-carousel-mobile-only]')) as HTMLElement[];
    jobListingBlockCarousels.forEach(carouselEl => {
        new MobileOnlyCardCarousel(carouselEl);
    });
}

const initCardCarouselBlocks = () => {
    const cardCarouselEls = [].slice.call(document.querySelectorAll('[data-carousel-cards]')) as HTMLElement[];
    cardCarouselEls.forEach(cardCarouselEl => {
        new CardCarousel(cardCarouselEl);
    })
}

const initWideCarouselBlocks = () => {
    const wideCarouselEls = [].slice.call(document.querySelectorAll('[data-carousel-wide]')) as HTMLElement[];
    wideCarouselEls.forEach(wideCarouselEl => {
        new WideCarousel(wideCarouselEl);
    })
}

const initVideoCarouselBlocks = () => {
    const wideCarouselEls = [].slice.call(document.querySelectorAll('[data-carousel-video]')) as HTMLElement[];
    wideCarouselEls.forEach(wideCarouselEl => {
        new VideoCarousel(wideCarouselEl);
    })
}

const initSplitCarouselBlocks = () => {
    const splitCarouselEls = [].slice.call(document.querySelectorAll('[data-carousel-split]')) as HTMLElement[];
    splitCarouselEls.forEach(splitCarouselEl => {
        new SplitCarousel(splitCarouselEl);
    })
}

const initLinearBlocks = () => {
    const linearBlockEls = [].slice.call(document.querySelectorAll('[data-linear-carousel]')) as HTMLElement[];
    linearBlockEls.forEach(linearBlockEl => {
        new LinearCarousel(linearBlockEl);
    })
}


initAll();