import Splide from '@splidejs/splide';
import { DirectionalSplide } from '../helpers/directionalSplide';
import { CarouselInLinearCarousel } from './carouselInLinearCarousel';

// Ignore the typescript issues on the pagination property and slideFocus, it's just incorrect typings from the library
export class LinearCarousel {
    container: HTMLElement;
    carousel: Splide;
    breakpoint: number;
    activeStage: number;
    stages: HTMLElement[];
    triggers: HTMLButtonElement[];
    paginationTriggers: HTMLButtonElement[];
    stageList: HTMLElement;
    transitionLength: number;

    constructor(element: HTMLElement) {
        this.container = element;
        this.breakpoint = 1024;
        this.transitionLength = 660;
        this.initAccordion();
        this.initCarousel();
    }

    initAccordion() {
        // Sideways accordion support
        this.stages = [].slice.call(this.container.querySelectorAll('[data-stage]')) as HTMLElement[];
        this.triggers = [];
        this.paginationTriggers = [];
        this.activeStage = null;
        this.stageList = this.container.querySelector('[data-stage-list]') as HTMLElement;
        this.stages.forEach((stage, index) => {
            const stageID = stage.getAttribute('data-stage');
            const stageButton = stage.querySelector('button');
            const paginationButton = document.querySelector(`[aria-controls="${stageID}"]`) as HTMLButtonElement;
            const innerCarousel = stage.querySelector('[data-inner-carousel]') as HTMLElement;
            const isStageOpen = stage.hasAttribute('data-default-open');
            stageButton.setAttribute('aria-controls', stageID);
            stageButton.setAttribute('aria-expanded', 'false');
            this.triggers.push(stageButton);
            stageButton.addEventListener('click', () => this.navigationClick(index));
            if (paginationButton) {
                paginationButton.setAttribute('aria-expanded', 'false');
                this.paginationTriggers.push(paginationButton);
                paginationButton.addEventListener('click', () => this.navigationClick(index));
            }
            if (innerCarousel) {
                this.initInnerCarousel(innerCarousel);
            }
            if (isStageOpen) {
                this.activeStage = index;
            }
        });
        if (this.activeStage != null) {
            setTimeout(() => {
                this.openPanel(this.activeStage);
            }, 0);
        }
    }

    initCarousel() {
        this.carousel = new Splide(this.container, {
            accessibility: true,
            drag: true,
            perPage: 1,
            destroy: true,
            // @ts-ignore
            pagination: 'slider',
            // @ts-ignore
            slideFocus: false,
            speed: 500,
            keyboard: 'focused',
            arrows: false,
            gap: '0',
            breakpoints: {
                1024: {
                    destroy: false,
                    padding: {
                        left: '4rem',
                        right: 'calc(4rem + 8.33%)'
                    }
                },
                600: {
                    destroy: false,
                    padding: {
                        left: '2rem',
                        right: 'calc(2rem + 8.33%)'
                    }
                }
            },
            classes: {
                pagination: 'splide__pagination carousel-hex-pagination',
                page: 'splide__pagination__page carousel-hex-page'
            }
        });

        this.carousel.mount({ DirectionalSplide });
    }

    initInnerCarousel(container: HTMLElement) {
        new CarouselInLinearCarousel(container);
    }

    navigationClick(index: number) {
        if (this.activeStage == null || this.activeStage !== index) {
            if (this.activeStage !== null) {
                this.closePanel(this.activeStage);
                setTimeout(() => { this.openPanel(index) }, this.transitionLength);
            }
            else {
                this.openPanel(index);
            }
            this.activeStage = index;
        }
        else if (this.activeStage == index) {
            this.closePanel(this.activeStage);
            this.activeStage = null;
        }
    }

    openPanel(index: number) {
        const containerAlign = (index + 1) > Math.floor(this.stages.length / 3 * 2) ? 'flex-end' : (index + 1) < Math.ceil(this.stages.length / 3) ? 'flex-start' : 'center';
        this.stageList.style.justifyContent = containerAlign;
        this.stages[index].classList.add('open');
        this.triggers[index].setAttribute('aria-expanded', 'true');
        if (this.paginationTriggers[index]) {
            this.paginationTriggers[index].setAttribute('aria-expanded', 'true');
        }
    }

    closePanel(index: number) {
        this.stages[index].classList.remove('open');
        this.triggers[index].setAttribute('aria-expanded', 'false');
        if (this.paginationTriggers[index]) {
            this.paginationTriggers[index].setAttribute('aria-expanded', 'false');
        }
    }
}