import Splide, { PaginationData, PaginationItem } from '@splidejs/splide';
import { DirectionalSplide } from '../helpers/directionalSplide';

// Ignore the typescript issues on the pagination property and slideFocus, it's just incorrect typings from the library
export class SplitCarousel {
    carousel: Splide;
    constructor(element: HTMLElement) {
        this.carousel = new Splide(element, {
            accessibility: true,
            drag: true,
            perPage: 3,
            perMove: 1,
            // @ts-ignore
            pagination: 'slider',
            // @ts-ignore
            slideFocus: false,
            speed: 500,
            focus: 0,
            keyboard: 'focused',
            padding: {
                left: '1rem',
                right: '1rem'
            },
            gap: '2rem',
            breakpoints: {
                1440: {
                    perPage: 3,
                    focus: 0,
                },

                1200: {
                    perPage: 2,
                },

                1024: {
                    perPage: 1,
                },

                760: {
                    perPage: 1,
                    padding: {
                        left: '1rem',
                        right: '1rem'
                    },
                    heightRatio: .75,
                    fixedWidth: 300,
                    focus: 'center'
                }
            },
            classes: {
                pagination: 'splide__pagination carousel-pagination carousel-pagination-nums',
                page: 'splide__pagination__page carousel-page',
                arrows: 'splide__sidebar'
            }
        });

        this.carousel.mount({ DirectionalSplide });
    }
}