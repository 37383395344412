import Splide, { PaginationData, PaginationItem } from '@splidejs/splide';

// We need to extend the library (via its own documentation) and add our listeners inside the mount method
// because they are not automatically reattached when the slider is destroyed / remounted at different breakpoints
export const TimelineSplide = (Splide: Splide) => {

    const setPaginationNumbers = () => {
        const paginationEl = Splide.Components.Pagination.data.list;
        [].slice.call(paginationEl.children).forEach((page, index) => {
            const relatedSlide = Splide.Components.Elements.slides[index];
            const offset = relatedSlide.getAttribute('data-date-offset');
            const button = page.querySelector('button');
            button.style.left = `${offset}%`;
        })
    }

    return {
        mount() {
            Splide.on('pagination:mounted', setPaginationNumbers.bind(this));
        }
    }
}